<template>
    <div class="block-item">
        <div class="row align-items-center">
            <div class="col">
                <slot></slot>
            </div>
            <div class="col-auto">
                <a href="javascript:void(0);" @click="onRemoveItem" class="block-item-remove">&times;</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BlockItem',
    emits: [
        'remove'
    ],
    methods: {
        onRemoveItem() {
            this.$emit('remove');
        }
    }
}
</script>
<style lang="scss">
.block-item {
    background-color: #e0e0e0;
    padding: 10px 5px;
    border-radius: 10px;
    margin-bottom: 10px;

    .block-item-remove {
        display: block;
        background-color: #CCCCCC;
        text-decoration: none;
        padding: 2px 11px 4px 11px;
        font-size: 20px;
        color: darkred;
        border-radius: 100%;

        &:hover {
            background-color: #DDDDDD;
        }
    }
}
</style>