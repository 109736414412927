<template>
  <template v-if="loadingDone">
    <div id="main">
      <header>
        <div class="container">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4 logo">
              <a href="/">
                <img src="./assets/logo.png" alt="Allart" width="200" />
              </a>
            </div>
            <div class="col-4"></div>
          </div>
        </div>
      </header>
      <div style="margin: 20px 0;">
        <MainMenu @select-item="onSelectMenuItem" :menu="getMainMenu().menu" />
      </div>
      <div class="container">
          <div class="row">
            <div class="col">
              <MainCarousel @itemSelected="onItemSelected" :items="currentItems"/>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <hr />
            </div>
          </div>

          <EditorComponent ref="editor" />
      </div>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-auto">
              <a href="javascript:void(0)">
                <img src="./assets/logo.png" alt="Logo" width="150">
              </a>
            </div>
            <div class="col align-self-center">
              <!--FOOTER-TEXT-->
              ALLARTGROUP 2023 © Қыз ұзату, сүндет той, 1 жылдық үйлену тойына тегін онлайн шақыру қағазын жасаңыз.
              <!--/FOOTER-TEXT-->
            </div>
          </div>
        </div>
      </footer>
    </div>
  </template>
  <template v-else>
    <LoadingScreen />
  </template>

</template>

<script>
// Config
import mainMenu from './config/menu.yaml'

import MainMenu from './components/menu/MainMenu.vue'
import MainCarousel from './components/menu/MainCarousel.vue';
import EditorComponent from './components/editor/EditorComponent.vue';
import LoadingScreen from './components/LoadingScreen.vue';



export default {
  name: 'App',
  components: {
    MainMenu,
    MainCarousel,
    EditorComponent,
    LoadingScreen
  },
  methods: {
    onSelectMenuItem(items) {
      console.log(items);
      this.currentItems = items;
    },
    getMainMenu() {
      return mainMenu;
    },

    // Когда выбрали новый шаблон
    onItemSelected(item) {
      if (!confirm('Вы действительно хотите изменить шаблон? Изменения не сохранятся')) {
        return;
      }

      this.$refs.editor.changeTemplate(item.fullUrl);
    }
  },
  data() {
    return {
      currentItems: mainMenu.defaultItems,
      loadingDone: true,
    }
  },
  mounted() {
    this.$refs.editor.changeTemplate(mainMenu.defaultItems[0].fullUrl);
  }
}
</script>

<style lang="scss">
body {
  background-color: #f5f5f5;
}

#main {
  & > header {
    background-color: rgb(0, 0, 0);
  }

  div.logo {
    padding: 10px 0;
    text-align: center;
  }
}

footer {
  background-color: black;
  padding: 30px 0;
  color: white;

  a {
    color: #08b16c;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
</style>
