<template>
    <div>
        <div class="row editor-component">
            <div class="col-2"></div>
            <div class="col-md-5" style="min-height: 500px;">
                <div ref="container" style="display: flex; justify-content: center;">
                    <canvas id="the-canvas"></canvas>
                </div>
            </div>
            <div class="col-md-3 add-buttons align-items-center align-items-md-start mt-4 mt-md-0">
                <!-- BLOCKS -->
                <PhotosBlock />
                <TextBlock />
                <DateBlock />
                <StickerBlock />
            </div>
            <div class="col-2"></div>
        </div>

        <div class="row editor-component">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="row buttons-green">
                    <div class="col-xs-12 col-md-4">
                        <a :href="config.urls.helpUrl" target="_blank" class="main-btn">
                            ПОМОЩЬ
                        </a>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <a href="javascript:void(0);" @click="printImage" class="main-btn">
                            РАСПЕЧАТАТЬ
                        </a>
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <a href="javascript:void(0);" @click="downloadImage" class="main-btn">
                            СКАЧАТЬ
                        </a>
                    </div>
                    <!-- <div class="col-xs-12 col-md-3" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley">
                        <a href="javascript:void(0);" class="main-btn">
                            ПОЖЕРТВОВАНИЕ
                        </a>
                    </div> -->
                </div>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row editor-component">
            <div class="offset-2 col-8" style="margin-bottom: 20px;">
                <AdsBottomComponent />
            </div>
        </div>
    </div>
</template>
<script>
//import AdsLeftComponent from '../ads/AdsLeftComponent.vue';
import AdsBottomComponent from '../ads/AdsBottomComponent.vue';
import PhotosBlock from './blocks/PhotosBlock.vue';
import TextBlock from './blocks/TextBlock.vue';
import DateBlock from './blocks/DateBlock.vue';
import {fabric} from 'fabric';
import urls from '../../config/urls'
import StickerBlock from './blocks/StickerBlock.vue';

window.gCanvas = null;

function canvasSaver() {
    console.log('export objects');
    localStorage.setItem('canvasState', JSON.stringify(window.gCanvas.toObject(['id', 'name', 'num', 'category'])));
}

export default {
    name: 'EditorComponent',
    components: {
        //AdsLeftComponent,
        AdsBottomComponent,
        PhotosBlock,
        TextBlock,
        DateBlock,
        StickerBlock,
    },
    data() {
        return {
            photos: [],
            config: {
                urls: urls,
            }
        };
    },
    mounted() {
        window.gCanvas = new fabric.Canvas('the-canvas');

        const oldState = localStorage.getItem('canvasState');

        if (oldState) {
            console.log('load old state');
            window.gCanvas.loadFromJSON(oldState);
        }

        // set events
        window.gCanvas.on({
            'object:modified': canvasSaver,
            'canvas:export': canvasSaver,
        });
    },
    methods: {
        downloadImage(e) {
            const canvas = window.gCanvas;

            if (!canvas) {
                return;
            }

            e.target.href = canvas.toDataURL({
                    format: 'jpeg',
                    quality: 1.0
                });
                e.target.download = 'image.jpg';
        },
        printImage() {
            const canvas = window.gCanvas;

            if (!canvas) {
                return;
            }

            var dataUrl = canvas.toDataURL();  
            var windowContent = '<!DOCTYPE html>';
            windowContent += '<html>'
            windowContent += '<head><title>Image</title></head>';
            windowContent += '<body style="text-align: center;">'
            windowContent += '<img src="' + dataUrl + '" onload="window.print();window.close();" style="max-width: 95vw;max-height: 95vh;">';
            windowContent += '</body>';
            windowContent += '</html>';
            var printWin = window.open('', '', 'width=' + canvas.getWidth() + ',height=' + canvas.getHeight());
            printWin.document.open();
            printWin.document.write(windowContent);
        },
        changeTemplate(url) {
            const canvas = window.gCanvas;

            canvas.clear();

            fabric.Image.fromURL(url, (img) => {
                const newSize = this.resizeCanvas(img.width, img.height);

                canvas.setWidth(newSize.width);
                canvas.setHeight(newSize.height);

                img.scaleToWidth(newSize.width);
                img.scaleToHeight(newSize.height);

                canvas.setBackgroundImage(img);
                window.gCanvas.fire('canvas:export', null);
            });
        },
        resizeCanvas(width, height) {
            const maxWidth = Math.min(width, this.$refs.container.offsetWidth);

            return {
                width: maxWidth,
                height: maxWidth / width * height
            }
        }
    }
}
</script>
<style lang="scss">
    $bg-color: #08b16c;
    $bg-color-hover: #0f784f;
    $border-radius: 10px;

    .editor-component {
        margin-top: 20px;
    }

    .main-btn {
        display: block;
        text-decoration: none;
        background-color: $bg-color;
        color: white;
        text-align: center;
        padding: 10px 5px;
        border-radius: $border-radius;

        &:hover {
            color: white;
            background-color: $bg-color-hover;
        }
    }

    .buttons-green {
        & > div {
            margin-bottom: 20px;
        }
    }

    .add-buttons {
        display: flex;
        flex-direction: column;
    }
</style>
