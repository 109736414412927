<template>
    <div>
        <div class="row align-items-center justify-content-center"  style="margin-bottom: 20px;">
            <div class="col">
                <p class="small text-muted">{{ d.title }}:</p>
                <input type="number" :max="d.max" :min="d.min" class="form-control form-control-sm" v-model="displayValue" @change="onChangeItem">
            </div>
            <div class="col-auto">
                <p class="small text-muted">Цвет:</p>
                <color-picker v-model:pureColor="state.color" @pureColorChange="onChangeItem" />
            </div>
            <div class="col-auto">
                <p class="small text-muted">Вкл:</p>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" value="" v-model="state.enabled"  @change="onChangeItem">
                </div>
            </div>
        </div>
        <hr style="margin-bottom: 20px;" />
    </div>
</template>
<script>
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

export default {
    name: 'DateBlockItem',
    components: {
        ColorPicker,
    },
    props: {
        title: String,
        modelValue: Object,
        max: Number,
        min: Number,
        offset: {
            type: Number,
            default: 0,
        }
    },
    emits: [
        'change',
        'update:modelValue'
    ],
    data() {
        return {
            d: {
                title: this.title,
                max: this.max,
                min: this.min,
            },
            state: {
                value: this.modelValue.value,
                color: this.modelValue.color,
                enabled: this.modelValue.enabled,
            },
        };
    },
    computed: {
        displayValue: {
            get() {
                return this.state.value + this.offset;
            },
            set(v) {
                this.state.value = v - this.offset;
            }
        }
    },
    methods: {
        onChangeItem() {
            this.$emit('update:modelValue', this.state);
            this.$emit('change', this.state);
        } 
    }
}
</script>