export default {
    months: {
        ru: [
            'қаңтар',
            'ақпан',
            'наурыз',
            'сәуір',
            'мамыр',
            'маусым',
            'шілде',
            'тамыз',
            'қыркүйек',
            'қазан',
            'қараша',
            'желтоқсан',
        ],
         uz: [
            'yanvar',
            'fevral',
            'mart',
            'aprel',
            'may',
            'iyun',
            'iyul',
            'avgust',
            'sentyabr',
            'oktyabr',
            'noyabr',
            'dekabr',
        ]
    }
}
