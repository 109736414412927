<template>
    <div class="row">
        <div class="col-auto">
            <a href="javascript:void(0);" class="block-header-btn" @click="onClick"></a>
        </div>
        <div class="col-auto block-header-title">
            <div style="width: 170px;">
                {{ title }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BlockHeader',
    props: {
        title: String
    },
    emits: ['addItem'],
    methods: {
        onClick() {
            this.$emit('addItem', null);
        }
    }
}
</script>
<style lang="scss">
    .block-header-btn {
        display: block;
        width: 24px;
        height: 24px;
        background-image: url('../../../assets/add-btn.png');
        background-position: 24px 0;
        
        &:hover {
            background-position: 0 0;
        }
    }

    .block-header-title {
        color: #232323;
        text-transform: uppercase;

        & > div {
            padding: 0px 10px;
        }
    }
</style>