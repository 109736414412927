<template>
    <div class="block-layout">
        <div class="block-layout-header">
            <BlockHeader :title="title" @addItem="onAddItem" />
        </div>
        <div class="block-layout-content">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import BlockHeader from './BlockHeader.vue'

export default {
    name: 'BlockLayout',
    components: {
        BlockHeader
    },
    props: {
        title: {
            type: String,
            default: 'No Title'
        }
    },
    emits: [
        'addItem',
    ],
    methods: {
        onAddItem() {
            this.$emit('addItem', null);
        }
    }
}
</script>
<style lang="scss">
.block-layout {
    .block-layout-content {
        margin-top: 20px;
    }
}
</style>