<template>
    <carousel :items-to-show="2">
        <slide v-for="slide in items" :key="slide">
            <a href="javascript:void(0);" @click="onItemClick(slide)">
                <img :src="slide.previewUrl" alt="Slide" class="carousel-item-slide"/>
            </a>
        </slide>
        <template #addons>
        <navigation />
        <pagination />
        </template>
    </carousel>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'MainCarousel',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    emits:['itemSelected'],
    methods: {
        onItemClick(item) {
            this.$emit('itemSelected', item);
        }
    }
}
</script>

<style lang="scss">
    .carousel-item-slide {
        max-width: 100px;
    }
</style>