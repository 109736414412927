<template>
    <div class="loading-screen">
        <img src="/loading.gif" alt="Loading" />
    </div>
</template>

<script>
export default {
    name: 'LoadingScreen',
}
</script>

<style lang="scss">
.loading-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}
</style>